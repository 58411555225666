import React, { Fragment } from 'react';

const PrivacyPolicyContent = () => (
  <Fragment>
    <p>
      <strong>POLITYKA PRYWATNOŚCI </strong>
    </p>
    <p>
      <strong>SafeSafe</strong>
    </p>

    <p>
      Zaprojektowaliśmy aplikację SafeSafe zgodnie z zasadami Privacy by Default
      oraz Privacy by Design. Oznacza to, że domyślnie stosujemy ochronę Twojej
      prywatności i staraliśmy się ograniczyć przetwarzanie Twoich danych już na
      etapie projektowania i tworzenia aplikacji SafeSafe. Staramy się nie
      pozyskiwać od Ciebie informacji, które umożliwią Twoją identyfikację (tj.
      danych osobowych), ale może się zdarzyć tak, że podczas korzystania z
      aplikacji podasz nam tyle informacji, że będziemy w stanie Cię
      zidentyfikować (nawet pośrednio) – oznacza to, że będziemy administratorem
      Twoich danych.
    </p>

    <p>
      Jeśli przekażesz nam informacje dotyczące Twojego zdrowia, wyrażasz
      zgodę na przetwarzanie przez nas tych danych. W każdej chwili możesz
      wycofać tą zgodę za pośrednictwem SafeSafe, bez wpływu na zgodność z
      prawem przetwarzania dokonanego przed jej wycofaniem.
    </p>

    <p>
      Przygotowaliśmy ten dokument, aby poinformować Cię jak przetwarzamy Twoje
      dane, a także jakie prawa Ci przysługują.
    </p>

    <p>
      <strong>§1.</strong>
    </p>
    <p>
      <strong>Postanowienia ogólne</strong>
    </p>
    <ol>
      <li>
        Niniejsza Polityka Prywatności określa zasady zbierania, przetwarzania i
        ochrony Danych Osobowych w związku z korzystaniem z aplikacji SafeSafe.
      </li>
      <li>
        Niniejszy dokument jest przygotowany w oparciu o postanowienia
        Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
        kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
        przetwarzaniem Danych Osobowych i w sprawie swobodnego przepływu takich
        danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
        ochronie danych), ustawy z dnia 10 maja 2018 r. o ochronie Danych
        Osobowych (Dz.U. z 2018 r. poz. 1000), ustawy z dnia 18 lipca 2002 r. o
        świadczeniu usług drogą elektroniczną (Dz. U. z 2020 poz. 344) oraz
        innych przepisów powszechnie obowiązujących.
      </li>
      <li>
        Administratorem Danych Osobowych Użytkowników strony internetowej jest
        TYTANI24 Spółka z ograniczoną odpowiedzialnością z siedzibą we
        Wrocławiu, ul. Ząbkowicka 55, 50 – 511 Wrocław (adres biura: ul.
        Kościerzyńska 32A, Wrocław, 51 – 410), wpisana do rejestru
        przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd
        Rejonowy we Wrocławiu, VI Wydział Gospodarczy Krajowego Rejestru
        Sądowego, pod numerem KRS 0000725465, REGON 369879064, NIP 8992843182, o
        kapitale zakładowym opłaconym w całości w wysokości 20 000,00 zł.
      </li>
      <li>
        W razie ogólnych pytań dotyczących prywatności, a także pytań
        dotyczących niniejszej Polityki prywatności lub wykorzystania Danych
        Osobowych i Plików <i>Cookies</i> zachęcamy do kontaktu pod adresem:
        info@safesafe.app.
      </li>
      <li>
        Administrator zapewnia, iż dokłada wszelkich starań, by Przetwarzanie
        przez niego Danych Osobowych odbywało się z największym poszanowaniem
        prywatności osób, których dane dotyczą oraz z najwyższą dbałością o
        bezpieczeństwo przetwarzanych Danych Osobowych, a w szczególności
        zapewnia, iż podjął wszelkie przewidziane prawem środki zmierzające do
        zabezpieczenia zbiorów Danych Osobowych.
      </li>
      <li>
        Administrator oświadcza, iż stosuje środki techniczne i organizacyjne
        zapewniające ochronę przetwarzanych Danych Osobowych odpowiednią do
        zagrożeń oraz kategorii danych objętych ochroną, a w szczególności
        zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym,
        zabraniem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem prawa
        oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.
      </li>
    </ol>

    <p>
      <strong>§2.</strong>
    </p>
    <p>
      <strong>Definicje</strong>
    </p>
    <p>Ilekroć w Polityce Prywatności mowa o: </p>
    <ol>
      <li>
        <strong>Administratorze</strong> – rozumie się przez to administratora w
        rozumieniu przepisów RODO, czyli TYTANI24 Spółka z ograniczoną
        odpowiedzialnością z siedzibą we Wrocławiu, ul. Ząbkowicka 55, 50 – 511
        Wrocław (adres biura: ul. Kościerzyńska 32A, Wrocław, 51 – 410), wpisana
        do rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego
        przez Sąd Rejonowy we Wrocławiu, VI Wydział Gospodarczy Krajowego
        Rejestru Sądowego, pod numerem KRS 0000725465, REGON 369879064, NIP
        8992843182, o kapitale zakładowym opłaconym w całości w wysokości 20
        000,00 zł;
      </li>
      <li>
        <strong>Danych Osobowych</strong> – rozumie się przez to podstawowe dane
        o Użytkowniku, m.in. nazwa Użytkownika, adres e-mail oraz inne dane
        podawane podczas korzystania z SafeSafe, dzięki którym możliwe jest
        określenie tożsamości w sposób bezpośredni lub pośredni;
      </li>
      <li>
        <strong>SafeSafe</strong> – rozumie się przez to aplikację SafeSafe;
      </li>
      <li>
        <strong>Usługach </strong>– rozumie się przez to usługi świadczone drogą
        elektroniczną oferowane Użytkownikom w ramach SafeSafe o których mowa w
        §3 ust. 1 Regulaminu;
      </li>
      <li>
        <strong>Koncie </strong>– przypisany do Użytkownika zbiór zasobów i
        uprawnień w SafeSafe oraz umożliwiający korzystanie z SafeSafe;
      </li>
      <li>
        <strong>Oprogramowaniu</strong> – rozumie się przez to przeglądarki
        internetowe, z których może korzystać Użytkownik, w celu korzystania z
        SafeSafe. Przykładowymi przeglądarkami internetowymi są: Opera, Chrome,
        Mozilla Firefox, Internet Explorer czy Safari;
      </li>
      <li>
        <strong>Plikach </strong>
        <strong>
          <i>Cookies</i>
        </strong>{' '}
        (tzw. ciasteczkach) – rozumie się przez to dane informatyczne
        stanowiące, w szczególności pliki tekstowe, które przechowywane są w
        Urządzeniu Użytkownika i przeznaczone są do korzystania z SafeSafe;{' '}
      </li>
      <li>
        <strong>Przetwarzaniu Danych Osobowych</strong> – rozumie się przez to
        operację lub zestaw operacji wykonywanych na Danych Osobowych lub
        zestawach Danych Osobowych w sposób zautomatyzowany lub
        niezautomatyzowany, taką jak zbieranie, utrwalanie, organizowanie,
        porządkowanie, przechowywanie, adaptowanie lub modyfikowanie,
        pobieranie, przeglądanie, wykorzystywanie, ujawnianie poprzez
        przesłanie, rozpowszechnianie lub innego rodzaju udostępnianie,
        dopasowywanie lub łączenie, ograniczanie, usuwanie lub niszczenie;
      </li>
      <li>
        <strong>Profilowaniu</strong> - rozumie się przez to przetwarzanie
        Danych Osobowych Użytkownika do oceny niektórych cech Użytkowników, w
        szczególności do analizy lub prognozy aspektów dotyczących zdrowia,
        osobistych preferencji, zachowania, lokalizacji lub przemieszczania się.
        Dzięki profilowaniu Twoich danych Administrator ma nadzieję wesprzeć
        działania mające na celu przeciwdziałaniu pandemii wirusa SARS-CoV-2;
      </li>
      <li>
        <strong>Regulaminie</strong> – rozumie się przez to dokument, który
        określa warunki korzystania z SafeSafe, a także prawa i obowiązki
        Administratora oraz Użytkowników;
      </li>
      <li>
        <strong>RODO</strong> – rozumie się przez to Rozporządzenia Parlamentu
        Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie
        ochrony osób fizycznych w związku z przetwarzaniem Danych Osobowych i w
        sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
        95/46/WE (ogólne rozporządzenie o ochronie danych);
      </li>
      <li>
        <strong>Urządzeniu</strong> – rozumie się przez to elektroniczne
        Urządzenie za pośrednictwem, którego Użytkownik uzyskuje dostęp do
        SafeSafe, a w szczególności: komputery klasy PC, laptopy, tablety,
        smartfony;
      </li>
      <li>
        <strong>Użytkowniku</strong> – rozumie się przez to osobę (Ciebie), na
        rzecz której zgodnie z Regulaminem i przepisami prawa są świadczone
        Usługi (SafeSafe);
      </li>
      <li>
        <strong>Zgodzie</strong> – rozumie się przez to dobrowolne, konkretne,
        świadome i jednoznaczne wyrażenie woli, którym osoba, której dane
        dotyczą, w formie oświadczenia lub wyraźnego działania, przyzwala na
        Przetwarzanie dotyczących jej Danych Osobowych.
      </li>
    </ol>

    <p>
      <strong>§3.</strong>
    </p>
    <p>
      <strong>Ogólne zasady Przetwarzania Danych Osobowych</strong>
    </p>
    <ol>
      <li> Dane Osobowe przetwarzane są wyłącznie w następujących celach:</li>
      <ol>
        <li>
          świadczenia Usług zgodnie z Regulaminem (usługi świadczone drogą
          elektroniczną) w tym w oparciu o zgodę na przetwarzanie danych
          dotyczących zdrowia;
        </li>
        <li>analiza, organizowanie i ulepszanie SafeSafe;</li>
        <li>
          profilowanie w celu przeciwdziałania pandemii wirusa SARS-CoV-2;
        </li>
        <li>
          pozostałe cele przetwarzania są wskazane w klauzulach informacyjnych.
        </li>
      </ol>
      <li>
        Dane Osobowe osób, których dane dotyczą, są przetwarzane na podstawie{' '}
        <strong>art. 6 ust. 1 lit. b RODO</strong>. Oznacza to, że są
        przetwarzane, gdy jest to konieczne do wykonania umowy o świadczenie
        usług drogą elektroniczną zawartej w oparciu o Regulamin oraz do
        podjęcia działań przed zawarciem umowy następujących na żądanie
        Użytkownika.{' '}
      </li>
      <li>
        Dane Osobowe są przetwarzane także na podstawie Zgody na przetwarzanie
        danych dotyczących zdrowia<strong> </strong>udzielonej przez
        Użytkownika, zgodnie z <strong>art. 9 ust. 2 lit. a RODO</strong>,
        koniecznych dla świadczenia Usług, w szczególności usługi Triażu
        (samooceny ryzyka infekcji wirusem SARS-CoV-2, stworzonej na podstawie
        kwestionariusza WHO).
      </li>
      <li>
        Dane Osobowe są przetwarzane, jeśli jest to niezbędne do realizacji
        celów wynikających z prawnie uzasadnionych interesów<strong> </strong>
        realizowanych przez Administratora zgodnie z{' '}
        <strong>art. 6 ust. 1 lit. </strong>
        <strong>f RODO</strong>, w szczególności takich jak profilowanie w celu
        przeciwdziałania pandemii SARS-CoV-2, obrona przed roszczeniami lub
        ustalenie i dochodzenie roszczeń z tytułu prowadzonej działalności
        gospodarczej czy analiza i ocena jakości Usług, udostępnienie Danych
        Osobowych odbiorcom określonym w ust. 10 niniejszego paragrafu w celach
        tam określonych.{' '}
      </li>
      <li>
        {' '}
        Administrator przestrzega następujących zasad Przetwarzania Danych
        Osobowych:
      </li>
      <ol>
        <li>
          wdraża odpowiednie środki techniczne i organizacyjne, aby
          przetwarzanie odbywało się zgodnie z przepisami o ochronie Danych
          Osobowych i aby móc to wykazać;
        </li>
        <li>
          utrwala zbierane Dane Osobowe wyłącznie na takich nośnikach
          informacji, które są zabezpieczone przed dostępem osób trzecich;{' '}
        </li>
        <li>
          wykonuje nadzór nad bezpieczeństwem danych przez cały okres ich
          posiadania w sposób zapewniający w szczególności ochronę przed
          dostępem osób nieuprawnionych, uszkodzeniem, zniszczeniem lub utratą;
        </li>
        <li>
          przekazuje dane uprawnionym podmiotom wyłącznie na podstawie
          obowiązujących przepisów prawa;
        </li>
        <li>zachowuje poufność Danych Osobowych;</li>
        <li>
          zapewnia osobom, których dane dotyczą, realizację ich praw
          wynikających z przepisów prawa.
        </li>
      </ol>
      <li>
        Administrator może przetwarzać następujące Dane Osobowe: identyfikator
        Urządzenia Użytkownika, nazwa Użytkownika (imię), dane dotyczące
        zdrowia, płeć, wiek, dane dotyczące palenia papierosów i inne, które
        Użytkownik zdecyduje się podać w SafeSafe lub których podanie będzie
        wymagane, a które umożliwią identyfikację Użytkownika.{' '}
      </li>
      <li>
        Podanie Danych Osobowych, o których mowa w ust. 7 niniejszego paragrafu,
        jest dobrowolne, lecz warunkuje korzystanie z SafeSafe.{' '}
      </li>
      <li>
        Ponadto przetwarzane będą wszelkie Dane Osobowe dobrowolnie przekazane
        przez Użytkownika w związku z jakąkolwiek formą kontaktu z
        Administratorem.{' '}
      </li>
      <li>
        Podanie dodatkowych Danych Osobowych może być wymagane w uzasadnionych
        okolicznościach, w szczególności w przypadku skierowania do
        Administratora uzasadnionego wniosku o przekazanie danych uprawnionym
        podmiotom.{' '}
      </li>
      <li>Odbiorcami Danych Osobowych mogą być:</li>
      <ol>
        <li>
          podmioty, które współpracują z Administratorem w celu świadczenia
          SafeSafe, a także profilowania i analizy Big Data dokonywanej w celu
          przeciwdziałania pandemii wirusa SARS-CoV-2 w tym. m.in. Infermedica
          Sp. z o.o. z siedzibą we Wrocławiu, Plac Solny 14/3, 50-062 Wrocław,
          wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego
          prowadzonego przez Sąd Rejonowy we Wrocławiu, VI Wydział Gospodarczy
          Krajowego Rejestru Sądowego, pod numerem KRS 0000429183, REGON
          021889810, NIP 8971782877, która świadczy Usługę Triażu (polityka
          prywatności Inframedica:{' '}
          <a
            href="https://infermedica.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://infermedica.com/privacy-policy
          </a>
          , informacje o Usłudze Triażu:{' '}
          <a
            href="https://developer.infermedica.com/docs/covid-19#triage"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://developer.infermedica.com/docs/covid-19#triage
          </a>
          );
        </li>
        <li>
          podmioty, którym dane przekazywane są na zasadzie powierzenia, o ile
          jest to konieczne dla prawidłowego realizowania Usług (np. obsługa IT,
          dostawcy hostingu);
        </li>
        <li>
          dostawcy Administratora, w szczególności podmioty świadczące usługi
          prawne lub informatyczne;
        </li>
        <li>
          organy administracji publicznej na podstawie przepisów prawa
          powszechnie obowiązującego.
        </li>
      </ol>
      <li>
        Przetwarzane Dane Osobowe nie są udostępniane Odbiorcom Danych w formie,
        która pozwalałaby na identyfikację, chyba że osoba, której dane dotyczą,
        wyraziła na to Zgodę lub jeżeli obowiązek udostępnienia zebranych
        informacji wynika z obowiązujących przepisów prawa.
      </li>
      <li>
        Dane Osobowe będą przechowywane nie dłużej niż trwa świadczenie Usług
        SafeSafe, a także nie dłużej niż jest to wymagane przepisami prawa i nie
        dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania, a
        ponadto nie dłużej niż jest to konieczne w celu dochodzenia lub obrony
        przed twierdzeniami i roszczeniami aż do momentu ich przedawnienia. Dane
        Osobowe będą przechowywane przez okres korzystania z SafeSafe dla celów
        realizacji Usług oraz związanych z nim funkcjonalności zgodnie z
        Regulaminem. Po zaprzestaniu korzystania z SafeSafe oraz usunięciu
        plików Cookies dane zostaną usunięte, za wyjątkiem następujących danych:
        logów SafeSafe, imienia, informacji pozostawionych w SafeSafe oraz
        informacji o wyrażonych Zgodach (te dane mogą być przechowywane jeszcze
        przez okres około 6 lat od zaprzestania korzystania z SafeSafe dla celów
        rozpatrywania ewentualnych skarg oraz roszczeń związanych z korzystaniem
        z SafeSafe).
      </li>
      <li>
        Dane Osobowe, których przetwarzanie odbywa się na podstawie Zgody (np.
        przetwarzanie danych dotyczących zdrowia), przechowywane będą do czasu
        cofnięcia Zgody na ich przetwarzanie. Użytkownik może w dowolnym czasie
        cofnąć Zgodę.{' '}
      </li>
      <li>Dane Osobowe nie będą przekazywane do państw trzecich.</li>
      <li>
        Dane Osobowe będą poddawane profilowaniu. Administrator nie podejmuje
        względem Użytkownika decyzji w sposób zautomatyzowany.
      </li>
    </ol>

    <p>
      <strong>§ 4.</strong>
    </p>
    <p>
      <strong>Prawa Użytkowników</strong>
    </p>
    <ol>
      <li>Osobom, których dane dotyczą, przysługuje:</li>
      <ol>
        <li>na podstawie art. 15 RODO prawo dostępu do Danych Osobowych;</li>
        <li>
          na podstawie art. 16 RODO prawo do sprostowania Danych Osobowych;
        </li>
        <li>na podstawie art. 17 RODO prawo do usunięcia Danych Osobowych;</li>
        <li>
          na podstawie art. 18 RODO prawo żądania od Administratora ograniczenia
          Przetwarzania Danych Osobowych z zastrzeżeniem przypadków, o których
          mowa w art. 18 ust. 2 RODO;
        </li>
        <li>
          prawo do przenoszenia Danych Osobowych, o którym mowa w art. 20 RODO;
        </li>
        <li>
          na podstawie art. 21 RODO prawo sprzeciwu wobec Przetwarzania Danych
          Osobowych.
        </li>
      </ol>
      <li>
        W celu realizacji praw wskazanych w ust. 1, należy przesłać wniosek za
        pośrednictwem poczty e-mail na adres: info@safesafe.app bądź pisemnie na
        adres siedziby Administratora.
      </li>
      <li>
        Osoba, której dane dotyczą, ma prawo wniesienia skargi do Prezesa Urzędu
        Ochrony Danych Osobowych, jeżeli uzna, że Przetwarzanie jego Danych
        Osobowych narusza przepisy RODO lub powszechnie obowiązujące przepisy.{' '}
      </li>
      <li>
        Użytkownik może w dowolnym czasie cofnąć Zgodę na przetwarzanie danych
        dotyczących zdrowia za pośrednictwem SafeSafe. W przypadku cofnięcia
        Zgody na przetwarzanie danych dotyczących zdrowia, informacje dotyczące
        zdrowia wprowadzone przez Użytkownika zostają bezpowrotnie usunięte.
      </li>
    </ol>

    <p>
      <strong>§5.</strong>
    </p>
    <p>
      <strong>Pliki </strong>
      <strong>
        <i>Cookies</i>
      </strong>
    </p>
    <ol>
      <li>
        {' '}
        Administrator za pośrednictwem SafeSafe wykorzystuje Pliki{' '}
        <i>Cookies</i>, czyli dane informatyczne, takie jak pliki tekstowe,
        które są przechowywane w Urządzeniu końcowym Użytkownika SafeSafe i są
        przeznaczone do korzystania z SafeSafe. W Plikach <i>Cookies</i>{' '}
        zapisane są Twoje informacje dotyczące zdrowia, za ich pośrednictwem
        prowadzisz także dziennik zdrowia:
      </li>
      <ol>
        <li>
          sesyjne – są to pliki tymczasowe, które przechowywane są w Urządzeniu
          Użytkownika do czasu wylogowania, opuszczenia SafeSafe lub wyłączenia
          Oprogramowania;
        </li>
        <li>
          stałe – są to pliki przechowywane w Urządzeniu Użytkownika przez
          określony w parametrach Plików <i>Cookies</i> lub do czasu ich
          usunięcia przez Użytkownika.
        </li>
      </ol>
      <li>
        W ramach SafeSafe mogą być stosowane wszystkie lub niektóre z
        następujących rodzajów Plików <i>Cookies:</i>
      </li>
      <ol>
        <li>
          {' '}
          „niezbędne”, które umożliwiają korzystanie z usług dostępnych w ramach
          SafeSafe;
        </li>
        <li>
          „wydajnościowe”, które umożliwiają zbieranie informacji o sposobie
          korzystania z SafeSafe;
        </li>
        <li>
          „funkcjonalne”, które umożliwiają zachowanie wybranych przez
          Użytkownika ustawień i personalizację interfejsu Użytkownika, np.
          poprzez język, rozmiar czcionki, wyglądu strony internetowej;
        </li>
        <li>
          „reklamowe”, które umożliwiają dostarczenie Użytkownikowi treści
          reklamowych bardziej dostosowanych do ich zainteresowań.
        </li>
      </ol>
      <li>
        Pliki <i>Cookies</i> wymienione w ust. 1 i 2 niniejszego paragrafu
        wykorzystywane są w celu:
      </li>
      <ol>
        <li>
          dostosowania zawartości SafeSafe do preferencji Użytkownika oraz
          optymalizacji korzystania z SafeSafe; w szczególności umożliwia to
          rozpoznanie i wyświetlenie SafeSafe na Urządzeniu Użytkownika,
          dostosowanego do jego indywidualnych preferencji;
        </li>
        <li>
          tworzenie statystyk, które wspierają obserwację korzystania z SafeSafe
          przez Użytkowników, co wpływa na ulepszenie jego struktury i treści;
        </li>
        <li>
          utrzymanie sesji Użytkownika SafeSafe (po zalogowaniu), dzięki której
          Użytkownik nie musi na każdej podstronie SafeSafe ponownie wpisywać
          loginu i hasła.
        </li>
      </ol>
      <li>
        Stosowane w SafeSafe rozwiązania są bezpieczne dla Urządzeń Użytkowników
        korzystających z tej strony.
      </li>
      <li>
        Oprogramowanie, z którego korzysta Użytkownik, zazwyczaj domyślnie
        dopuszcza przechowywanie Plików <i>Cookies</i> w Urządzeniu Użytkownika.
        Użytkownik w dowolnym momencie może dokonać zmiany ustawień dotyczących
        Plików <i>Cookies</i> w ustawieniach Oprogramowania, w szczególności w
        taki sposób, aby blokować automatyczną obsługę Plików <i>Cookies</i>{' '}
        bądź być informowanym o ich każdorazowym zamieszczeniu w Urządzeniu
        Użytkownika.
      </li>
      <li>
        Ograniczenia stosowania Plików <i>Cookies</i> mogą wpłynąć na niektóre
        funkcjonalności dostępne w SafeSafe.
      </li>
    </ol>

    <p>
      <strong>§6.</strong>
    </p>
    <p>
      <strong>Postanowienia końcowe</strong>
    </p>
    <ol>
      <li>
        W SafeSafe mogą pojawiać się linki do innych stron internetowych. Takie
        strony internetowe działają niezależnie od Administratora i nie są w
        żaden sposób przez niego nadzorowane. Strony te mogą posiadać własne
        polityki prywatności oraz regulaminy, z którymi zalecamy się zapoznać.{' '}
      </li>
      <li>
        Administrator zastrzega sobie prawo zmiany Polityki Prywatności poprzez
        opublikowanie nowej Polityki Prywatności na stronie SafeSafe.
      </li>
      <li>
        O wszelkich zmianach Polityki Prywatności Administrator będzie
        informować z wyprzedzeniem.
      </li>
    </ol>
  </Fragment>
);

export default PrivacyPolicyContent;
