import React from 'react';
import { useSelector } from 'react-redux';

import Home from './Home';
import { Diagnosis } from '../Diagnosis';

const HomeContainer = () => {
  const riskTest = useSelector(state => state.riskTest);

  if (Object.keys(riskTest).length === 0) {
    return <Diagnosis />;
  }
  return <Home />;
};

export default HomeContainer;
